<template>
  <div class="LandingPagePreview">
    <header v-if="dynamicContentOverrides" class="dynamicContentOverrides">
      <select v-model="dynamicContentKeyword">
        <option value="">default</option>
        <option v-for="{ keyword } in dynamicContentOverrides" :key="keyword" :value="keyword">{{ keyword }}</option>
      </select>
    </header>
    <component v-if="content" :is="component" :breadcrumbs="[]" v-bind="{ content }" />
  </div>
</template>

<style src="@inseego-digital/polymorphic-page/dist/polymorphicPage.css"></style>

<script>

import { merge as loMerge } from 'lodash'
import * as polymorphicPages from '@inseego-digital/polymorphic-page'

const componentsByType = {
  jumboLandingPage: polymorphicPages.JumboLandingPage,
  whitepaperLandingPage: polymorphicPages.WhitepaperLandingPage,
}

export default {
  name: 'LandingPagePreview',
  supportedDocTypes: [
    'jumboLandingPage',
    'polymorphicLandingPage',
    'whitepaperLandingPage',
  ],
  props: {
    sanityClient: {
      type: Object,
      default: null,
    },
    docId: {
      type: String,
      default: '',
    },
    docType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      component: null,
      defaultContent: null,
      dynamicContentOverrides: null,
      dynamicContentKeyword: '',
    }
  },
  created() {
    if (this.docType) {
      this.component = componentsByType[this.docType] || polymorphicPages.PolymorphicPage
      if (this.sanityClient) {
        const query = `{
          "content": ${this.component.contentQuery},
          "dynamicContentOverrides": *[_id == $id][0].dynamicContentOverrides,
        }`
        this.sanityClient.fetch(query, { id: this.docId })
          .then(({ content, dynamicContentOverrides }) => {
            this.defaultContent = content
            this.dynamicContentOverrides = dynamicContentOverrides
          })
      }
    }
  },
  computed: {
    content() {
      if (this.dynamicContentKeyword) {
        const activeOverride =
          this.dynamicContentOverrides.find(({ keyword }) => keyword === this.dynamicContentKeyword)
        if (activeOverride) {
          return loMerge({}, this.defaultContent, activeOverride.overrideContent)
        }
      }
      return this.defaultContent
    },
  },
}
</script>

<style>
.dynamicContentOverrides {
  padding: 0.5rem 1.5rem;
}
</style>

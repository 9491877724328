import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

export default {

  install(Vue) {

    extend('required', {
      validate(value) {
        if (typeof value === 'undefined') {
          return false
        }
        return value.toString().trim().length > 0
      },
      computesRequired: true,
      message: 'This field is required.',
    })

    extend('phone', {
      validate(value) {
        if (typeof value === 'undefined') {
          return false
        }
        return /^[EXText.+#\-()\d\s]{7,25}$/.test(value.trim())
      },
      message: 'Please enter a valid phone number.',
    })

    extend('email', {
      validate(value) {
        if (typeof value === 'undefined') {
          return false
        }
        return /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,24}$/.test(
          value.trim()
        )
      },
      message: 'Please enter a valid email address.',
    })

    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
  }
}

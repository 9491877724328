import FakeRouterLink from './FakeRouterLink.vue'

export default {
  // A fake vue-router, for the sake of the resource index pages.
  install(Vue) {
    Vue.prototype.$route = {
      path: '/',
      query: '',
      meta: {},
    }
    Vue.prototype.$router = {
      getRoutes() {
        return []
      }
    }
    Vue.component('router-link', FakeRouterLink)
  }
}

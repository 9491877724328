export default {

  install(Vue) {

    // Valid variants are:
    // - '5g' or 'fleet' for lead submissions
    // - 'form' for the in-page form
    // - 'ebook' for the gated content download module
    Vue.prototype.$submitForm = function (options, fieldValues) {
      const { locale, variant } = options
      const endpoint = `https://jarvis.inseego.com/inseego-${variant}-${locale}`
      console.log({ endpoint, fieldValues })
      return new Promise(
        (resolve) => {
          setTimeout(() => { resolve({ ok: true }) }, 300)
        }
      )
    }
  }
}

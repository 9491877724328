import Vue from 'vue'
import App from './App.vue'

import bodyScrollLock from './plugins/body-scroll-lock'
import config from './plugins/config.js'
import fakeRouter from './plugins/fake-router.js'
import pageAction from './plugins/page-action.js'
import submitForm from './plugins/submit-form.js'
import sharedInseegoComponents from './plugins/shared-inseego-components.js'
import veeValidate from './plugins/vee-validate.js'

Vue.config.productionTip = false

Vue.use(bodyScrollLock)
Vue.use(config, {})
Vue.use(fakeRouter)
Vue.use(pageAction)
Vue.use(submitForm)
Vue.use(sharedInseegoComponents)
Vue.use(veeValidate)

new Vue({
  render: h => h(App),
}).$mount('#app')

<template>
  <span><slot /></span>
</template>

<script>
export default {
  props: {
    to: String,
  }
}
</script>

<template>
  <component v-if="component" :is="component" v-bind="componentProps" />
  <pre v-else>No preview available for '{{ componentProps.docType }}' document</pre>
</template>

<style src="@inseego-digital/button-styles/dist/buttonStyles.css"></style>

<script>

import createSanityClient from '@sanity/client'

import '@inseego-digital/baseline-styles'

import LandingPagePreview from './views/LandingPagePreview.vue'
import ProductDocPagePreview from './views/ProductDocPagePreview.vue'
import ResourcePagePreview from './views/ResourcePagePreview.vue'
import VarietyPagePreview from './views/VarietyPagePreview.vue'

const pagePreviewComponents = [
  VarietyPagePreview,
  LandingPagePreview,
  ResourcePagePreview,
  ProductDocPagePreview,
]

function getUrlParams() {
  const entries = document.location.search.slice(1).split('&')
    .map(str => str.split('='))
    .map(([name, value]) => [name, decodeURIComponent(value)])
  return Object.fromEntries(entries)
}

export default {
  name: 'App',
  data() {
    return {
      component: null,
      componentProps: {},
    }
  },
  created() {
    const { id, type, projectId, dataset } = getUrlParams()
    if (id && projectId && dataset) {
      this.$config.sanity = { projectId, dataset }
      const sanityClient = createSanityClient({
        projectId,
        dataset,
        apiVersion: '2022-02-22',
        withCredentials: true,
        useCdn: false,
      })
      this.component = pagePreviewComponents.find(({ supportedDocTypes }) => supportedDocTypes.includes(type))
      this.componentProps = { sanityClient, docId: id, docType: type }
    }
  },
}
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>

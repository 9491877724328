<template>
  <div class="ResourcePagePreview" v-if="content">
    <component :is="component" v-if="docType.endsWith('IndexPage')"
      v-bind="{ breadcrumbs, content, contentTypeLinks }"
    />
    <div v-else>
      <div class="articlePreviewBox">
        <EventPreview v-if="docType === 'eventItem'" :content="previewContent" />
        <WebinarPreview v-else-if="docType === 'webinarItem'" :content="previewContent" />
        <ArticlePreview v-else :content="previewContent" class="articlePreview" />
      </div>
      <component :is="component" v-if="docHasArticleView"
        v-bind="{ breadcrumbs, content, parentPath }"
      />
    </div>
  </div>
</template>

<style src="@inseego-digital/resource-pages/dist/resourcePages.css"></style>

<script>
import * as resourcePageComponents from '@inseego-digital/resource-pages'

const { ArticlePreview, EventPreview, WebinarPreview } = resourcePageComponents

const componentForType = {
  'blogPost': resourcePageComponents.BlogPost,
  'caseStudy': resourcePageComponents.CaseStudy,
  'newsItem': resourcePageComponents.NewsItem,
  'glossary5gItem': resourcePageComponents.GlossaryEntry,
  'glossaryFleetItem': resourcePageComponents.GlossaryEntry,
  'pressRelease': resourcePageComponents.PressRelease,
  'resourceIndexPage': resourcePageComponents.IndexPage,
  'eventIndexPage': resourcePageComponents.EventIndexPage,
  'webinarIndexPage': resourcePageComponents.WebinarIndexPage,
}

const dummyBreadcrumbs = [
  { title: 'Home', path: '/' },
  { title: 'Parent path', path: '/' },
  { title: 'Page path', path: '/' },
]

const dummyContentTypeLinks = [
  { _key: 'blog', text: 'Blog', url: '#' },
  { _key: 'news', text: 'News', url: '#' },
]

const localeStrings = {
  "learnMore": "Learn more",
  "pastEvent": "Past event",
  "readMore": "Read more",
  "sourceSource": "Source: {source}",
  "watchNow": "Watch now"
}

export default {
  name: 'ResourcePagePreview',
  supportedDocTypes: Object.keys(componentForType),
  provide: { localeStrings },  // Required for Event/Webinar/ArticleView
  components: { ArticlePreview, EventPreview, WebinarPreview },
  props: {
    sanityClient: {
      type: Object,
      default: null,
    },
    docId: {
      type: String,
      default: '',
    },
    docType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      component: null,
      breadcrumbs: dummyBreadcrumbs,
      content: null,
      contentTypeLinks: dummyContentTypeLinks,
      parentPath: '#',
      previewContent: null,
    }
  },
  computed: {
    docHasArticleView() {
      const docTypesWithArticleView = ['blogPost', 'caseStudy', 'glossary5gItem', 'glossaryFleetItem', 'newsItem', 'pressRelease']
      return docTypesWithArticleView.includes(this.docType) && !this.previewContent.isExternalArticle
    }
  },
  created() {
    if (this.sanityClient) {
      this.component = componentForType[this.docType];
      this.sanityClient.fetch(this.component.contentQuery, { id: this.docId })
        .then((content) => { this.content = content })
      this.sanityClient.getDocument(this.docId)
        .then((previewContent) => { this.previewContent = previewContent })
    }
  },
}
</script>

<style scoped>
.articlePreviewBox {
  padding: var(--spacing-6);
}
.articlePreview {
  max-width: 296px;
}
</style>

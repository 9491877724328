<template>
  <div class="VarietyPagePreview">
    <component
      v-if="component && content"
      :is="component"
      v-bind="{ breadcrumbs, content }"
    />
  </div>
</template>

<style src="@inseego-digital/polymorphic-page/dist/polymorphicPage.css"></style>

<script>
import * as polymorphicPages from '@inseego-digital/polymorphic-page'

const componentsByType = {
  contactPage: polymorphicPages.ContactPage,
  homepage: polymorphicPages.Homepage,
  leadershipPage: polymorphicPages.LeadershipPage,
  legalDocumentPage: polymorphicPages.LegalDocumentPage,
  productPage: polymorphicPages.ProductPage,
  supportInfoPage: polymorphicPages.SupportInfoPage,
  upgrade3g4gPage: polymorphicPages.Upgrade3gTo4gPage,
}

const dummyBreadcrumbs = [
  { title: 'Home', path: '/' },
  { title: 'Parent path', path: '/' },
  { title: 'Page path', path: '/' },
]

export default {
  name: 'VarietyPagePreview',
  supportedDocTypes: [
    'contactPage',
    'homepage',
    'leadershipPage',
    'legalDocumentPage',
    'polymorphicPage',
    'productCategoryPage',
    'productPage',
    'supportInfoPage',
    'upgrade3g4gPage',
  ],
  props: {
    sanityClient: {
      type: Object,
      default: null,
    },
    docId: {
      type: String,
      default: '',
    },
    docType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      breadcrumbs: dummyBreadcrumbs,
      component: null,
      content: null,
    }
  },
  created() {
    if (this.docType) {
      this.component = componentsByType[this.docType] || polymorphicPages.PolymorphicPage
      if (this.sanityClient) {
        this.sanityClient.fetch(this.component.contentQuery, { id: this.docId })
          .then((content) => { this.content = content })
      }
    }
  },
}
</script>
